/****************************************************************************************************
 * Constants and environment variables.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 12 January 2021
 * @lastModifiedAt Wednesday 22 December 2021
 ****************************************************************************************************/

export default {};

export const CLOUTLAYER_WEBAPP_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_URL;
export const CLOUTLAYER_WEBAPP_CORE_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_CORE_URL;
export const CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_SUBSCRIPTIONS_URL;
export const CLOUTLAYER_WEBAPP_BASE_URL = process.env.VUE_APP_CLOUTLAYER_WEBAPP_BASE_URL;

export const CLOUTLAYER_KERNEL_BASE_URL = process.env.VUE_APP_CLOUTLAYER_KERNEL_BASE_URL;
export const CLOUTLAYER_KERNEL_API_VERSION = process.env.VUE_APP_CLOUTLAYER_KERNEL_API_VERSION;

export const GATEWAY_HESTIA_BASE_URL = process.env.VUE_APP_GATEWAY_HESTIA_BASE_URL;
export const SERVICE_N_UTILITIES_BASE_URL = process.env.VUE_APP_SERVICE_N_UTILITIES_BASE_URL;

export const CLOUTLAYER_WEBSITES_PAGE_URL_SCHEME = process.env.VUE_APP_CLOUTLAYER_WEBSITES_PAGE_URL_SCHEME;

export const SILENT_TRANSLATION_WARNINGS = (process.env.VUE_APP_SILENT_TRANSLATION_WARNINGS === 'true');
export const SILENT_FALLBACK_WARNINGS = (process.env.VUE_APP_SILENT_FALLBACK_WARNINGS === 'true');

export const GTAG_ENABLED = (process.env.VUE_APP_GTAG_ENABLED === 'true');
export const GTAG_PROPERTY_ID = process.env.VUE_APP_GTAG_PROPERTY_ID;
export const GTAG_DEBUG = (process.env.VUE_APP_GTAG_DEBUG === 'true');

// WebApp Core read-to-use URLs.
export const CLOUTLAYER_WEBAPP_CORE_LOGIN_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/login';
export const CLOUTLAYER_WEBAPP_CORE_LOGOUT_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/logout';
export const CLOUTLAYER_WEBAPP_CORE_SIGN_UP_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/sign-up';
export const CLOUTLAYER_WEBAPP_CORE_PROFILE_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/profile';
export const CLOUTLAYER_WEBAPP_CORE_TENANT_ACCOUNTS_URL = CLOUTLAYER_WEBAPP_CORE_URL + '/accounts';

// WebApp multi-page configuration.
export const ROOT_BASE_URL = '/root/';
export const TENANT_BASE_URL = '/tenant/';
export const USER_BASE_URL = '/user/';
export const PEOPLE_BASE_URL = '/people/';
export const WEBSITES_BASE_URL = '/websites/';
