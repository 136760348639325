/****************************************************************************************************
 * Vue router.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

import { createRouter, createWebHistory } from 'vue-router';

import { RouterUtils } from '@/core/utils';

import ModulesRoutes from './modules-routes';

const routes = [
    ...ModulesRoutes
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: RouterUtils.scrollBehaviorToBody
});

export default router;
