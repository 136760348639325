/****************************************************************************************************
 * Vuex Store.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

import { createStore } from 'vuex';
import ApplicationStoreModule from '@/core/store/application/index.module';
import ModulesStores from './modules-stores';

const modules = {
    application: ApplicationStoreModule,
    ...ModulesStores
};

export default createStore({
    modules: modules,
    devtools: true
});
