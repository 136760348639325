/****************************************************************************************************
 * A main.js helper that registers global Vue directives.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

import {
    ClickOutside
} from '@/core/directives';

export default {
    register (vueApp) {
        vueApp.directive('click-outside', ClickOutside);
    }
};
