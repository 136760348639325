/****************************************************************************************************
 * A main.js helper that registers global Vue filters.
 *
 * @see https://v3.vuejs.org/guide/migration/filters.html#global-filters
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

import {
    dateFormat
} from '@/core/filters';

export default {
    register (vueApp) {
        vueApp.config.globalProperties.$filters = {};
        vueApp.config.globalProperties.$filters['dateFormat'] = dateFormat;
    }
};
