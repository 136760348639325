/****************************************************************************************************
 * Modules routes registration and processing.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

const RegisteredModulesRoutes = [];

// noinspection UnnecessaryLocalVariableJS
const ModulesRoutes = RegisteredModulesRoutes
    .map((route) => {
        // 1st way.
        // route.path = '/:tenantId' + route.path;

        // 2nd way.
        if (route.path.startsWith('/')) {
            route.path = route.path.substring(1);
        }

        return route;
    });

export default ModulesRoutes;
