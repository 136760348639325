/****************************************************************************************************
 * Vue entry file.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

import { createApp } from 'vue';

import '@/styles/styles.css';
import '@/core/components/VeeValidate/styles.css';

// import '@/core/registerServiceWorker';

import App from './App.vue';
import router from './router';
import store from './store';

import { enableGtag, enableGtagTrackRouter } from '@/core/modules/MarketingAnalytics';
import { I18nPlugin, VeeValidatePlugin } from '@/core/plugins';
import GlobalComponents from './global-components';
import GlobalDirectives from './global-directives';
import GlobalFilters from './global-filters';

const i18n = I18nPlugin.getDefaultI18nInstance();
VeeValidatePlugin.initialize();
VeeValidatePlugin.setLocale('en'); // May change during init.

const app = createApp(App)
    .use(store)
    .use(router)
    .use(i18n);
app.config.devtools = true;
enableGtagTrackRouter(router);
enableGtag(app);
GlobalComponents.register(app);
GlobalDirectives.register(app);
GlobalFilters.register(app);
app.mount('#app');
