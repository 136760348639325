/****************************************************************************************************
 * Modules store modules registration and processing.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

const RegisteredModulesStores = {};

// noinspection UnnecessaryLocalVariableJS
const ModulesStores = RegisteredModulesStores;

export default ModulesStores;
