<template>
    <Spinner v-if="!initializedAndReady" key="application-initializing"/>
    <router-view v-else key="application-initialized-and-ready"/>
</template>

<script>
import { computed  } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ApiClient } from '@/core/api';
import { Spinner } from '@/core/components';
import { useApplication } from '@/core/composables';
import { VeeValidatePlugin } from '@/core/plugins';

/**
 * Entry component.
 *
 * @author Dimitris Gkoulis
 * @createdAt 14 May 2021
 * @lastModifiedAt Wednesday 29 December 2021
 */
export default {
    name: 'App',
    components: {
        Spinner
    },
    setup () {
        const router = useRouter();
        const store = useStore();
        const {
            initializeNonMultiTenantApp,
            runLazyAuthRouteGuard,
            initializeAxiosTenantIdInterceptor,
            initializeAxiosErrorTransformingInterceptor,
            setApplicationStateNotReady,
            getRedirectUrlBasedOnRoute,
            redirectToUrl
        } = useApplication();

        const i18nMessageProviderAndPluginSetUp = async function (languageKey) {
            VeeValidatePlugin.setLocale(languageKey);

            let messages;
            if (languageKey === 'el') {
                messages = await import(/* webpackChunkName: "home-lang-el" */ '@/user/locale/el')
                    .then(messages => messages.default).catch(() => void 0);
            } else {
                messages = await import(/* webpackChunkName: "home-lang-en" */ '@/user/locale/en')
                    .then(messages => messages.default).catch(() => void 0);
            }
            return Promise.resolve({
                messages: messages
            });
        };

        const defaultAxiosInstance = ApiClient.getDefaultAxiosInstance();
        initializeAxiosTenantIdInterceptor(defaultAxiosInstance);
        initializeAxiosErrorTransformingInterceptor(defaultAxiosInstance);

        router.beforeEach((to, from, next) => {
            initializeNonMultiTenantApp(i18nMessageProviderAndPluginSetUp)
                .then((initResult) => {
                    if (initResult.success === true) {
                        runLazyAuthRouteGuard(to).then(() => void 0).catch(() => void 0);
                    } else {
                        setApplicationStateNotReady();
                        redirectToUrl(getRedirectUrlBasedOnRoute(to));
                    }
                })
                .catch(() => {
                    setApplicationStateNotReady();
                    redirectToUrl(getRedirectUrlBasedOnRoute(null));
                });

            next();
        });

        return {
            initializedAndReady: computed(() => store.getters['application/initializedAndReady'])
        };
    }
};
</script>
