/****************************************************************************************************
 * A main.js helper that registers global Vue components.
 *
 * Include only the components that are used globally from more than one components.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 14 May 2021
 ****************************************************************************************************/

import {
    Button,
    CommonContainer,
    CommonContainerWidthControl,
    CommonDropdown,
    CommonEmptyState,
    CommonErrorState,
    CommonHeader,
    CommonLoading,
    CommonLoadingBlocking,
    CommonNotFound,
    CommonWrapper,
    HeadMeta,
    HeadMetaOptions,
    VvCheckboxBinary,
    VvDateTimeInput,
    VvInputHidden,
    VvInputText,
    // VvListInputText,
    // VvListStoredObjectInput,
    VvSelect,
    // VvStoredObjectInput,
    VvTextarea
} from '@/core/components';

export default {
    register (vueApp) {
        vueApp.component('Button', Button);
        vueApp.component('CommonContainer', CommonContainer);
        vueApp.component('CommonContainerWidthControl', CommonContainerWidthControl);
        vueApp.component('CommonDropdown', CommonDropdown);
        vueApp.component('CommonEmptyState', CommonEmptyState);
        vueApp.component('CommonErrorState', CommonErrorState);
        vueApp.component('CommonHeader', CommonHeader);
        vueApp.component('CommonLoading', CommonLoading);
        vueApp.component('CommonLoadingBlocking', CommonLoadingBlocking);
        vueApp.component('CommonNotFound', CommonNotFound);
        vueApp.component('CommonWrapper', CommonWrapper);
        vueApp.component('HeadMeta', HeadMeta);
        HeadMetaOptions.setOption('titleOd', 'Cloutlayer');
        HeadMetaOptions.setOption('ods', 'disable');
        vueApp.component('VvCheckboxBinary', VvCheckboxBinary);
        vueApp.component('VvDateTimeInput', VvDateTimeInput);
        vueApp.component('VvInputHidden', VvInputHidden);
        vueApp.component('VvInputText', VvInputText);
        // vueApp.component('VvListInputText', VvListInputText);
        // vueApp.component('VvListStoredObjectInput', VvListStoredObjectInput);
        vueApp.component('VvSelect', VvSelect);
        // vueApp.component('VvStoredObjectInput', VvStoredObjectInput);
        vueApp.component('VvTextarea', VvTextarea);
    }
};
